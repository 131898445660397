import React, { useEffect, memo } from 'react'
import { motion } from 'framer-motion';
import image18 from '../../assets/media/18.GIF';
import image19 from '../../assets/media/19.GIF';
import image20 from '../../assets/media/20.gif';



// Review Component
const Review = memo(({ imageSrc, customerName, reviewText }) => (
  <div className="w-64 rounded shrink-0 flex flex-col">
    <img src={imageSrc} alt={`${customerName}'s review`} className="object-contain w-auto h-[450px]"
      onLoad={(e) => {
        e.target.style.filter = 'none';
      }}
      style={{
        filter: "blur(8px)",
        transition: "filter 1.6s ease-in-out" // Adding height transition
      }} />
    <label className="pt-4 font-normal text-white">{customerName}</label>
    <p className="pt-4 text-white font-light">{reviewText}</p>
  </div>
));

// Reviews Section Component
const ReviewsSection = () => {
  const reviews = [
    {
      imageSrc: image18,
      customerName: 'Ankur',
      reviewText: '“I went from no hair on the top to all this. My favorite exercise is the scalp pinching.“',
    },
    {
      imageSrc: image19,
      customerName: 'Michael',
      reviewText: '“Hair on the sides and the top has regrown, scalp health has increased. This actually worked for me.” ',
    },
    {
      imageSrc: image20,
      customerName: 'Collin',
      reviewText: '“Hair has grown a lot and I see baby hairs. Proud to be a part of the KESHAH Family.“',
    },
  ];

  return (
    <div className='w-full overflow-x-auto hide-scrollbar'>
      <div className='flex gap-4'>
        {reviews.map((review, index) => (
          <Review key={index} {...review} />
        ))}
      </div>
    </div>
  );
};

export default function BookConsultation({ onPrevious }) {
  const animationDelay = 0.5; // Base delay for consistent animation timing

  useEffect(() => {
    // Scroll to top whenever the data (i.e., screen) changes
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='w-full sm:w-[420px] mx-auto min-h-screen p-4'>
      {/* Header */}
      <motion.div
        className='mt-6 w-full h-fit flex items-center justify-between'
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ ease: "easeInOut", duration: 0.5, delay: animationDelay }}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5 text-white cursor-pointer" onClick={onPrevious}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
        </svg>
        <img src="https://keshah.com/wp-content/uploads/2024/09/KESHAHWhiteLogoPNG-2.png" className="h-[24px] w-auto" alt="Keshah Logo" />
        <div className='appearance-none opacity-0'>as</div>
      </motion.div>


      <motion.h1 className='mt-6 text-white font-light text-4xl'
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ ease: "easeInOut", duration: 1.5, delay: animationDelay + 0.3 }}>
        Congratulations! You qualify for our free virtual hair regrowth consultation.
      </motion.h1>

      <motion.p className='mt-6 text-white font-light text-sm leading-loose tracking-wide'
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ ease: "easeInOut", duration: 1.5, delay: animationDelay + 0.6 }}>
        Find a time below to learn how KESHAH’s at-home treatment can reverse your hair loss without any drugs or side-effects.
      </motion.p>

      {/* Calendly Embed */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeInOut", duration: 1, delay: animationDelay + 1.5 }}
      >
        <div className="calendly-inline-widget mt-6 bg-black rounded-2xl overflow-hidden" data-url="https://calendly.com/d/cn87-qhh-rk7/hair-regrowth-consultation?hide_event_type_details=1&hide_gdpr_banner=1" style={{ minWidth: '320px', height: '600px' }}>
          {/* This iframe will render the Calendly widget */}
          <iframe
            src="https://calendly.com/d/cn87-qhh-rk7/hair-regrowth-consultation?hide_event_type_details=1&hide_gdpr_banner=1"
            width="100%"
            height="100%"
            frameBorder="0"
            title="Calendly Scheduling Widget"
          />
        </div>
      </motion.div>


      <motion.div className='mt-6'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeInOut", duration: 1, delay: animationDelay + 3.8 }}>
        <ReviewsSection />
      </motion.div>
    </div>
  )
}
