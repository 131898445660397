import image01 from '../assets/media/1.gif';
import image02 from '../assets/media/2.png';
import image03 from '../assets/media/3.gif';
import image04 from '../assets/media/4.png';
import image05 from '../assets/media/5.png';
import image06 from '../assets/media/6.png';
import image07 from '../assets/media/7.png';
import image08 from '../assets/media/8.gif';
import image09 from '../assets/media/9.png';
import image10 from '../assets/media/10.png';
import image11 from '../assets/media/11.png';
import image12 from '../assets/media/12.png';
import image13 from '../assets/media/13.png';
import image14 from '../assets/media/14.png';
import image15 from '../assets/media/15.png';
import image16 from '../assets/media/16.png';

const quizData = {
    hairgrowth: {
        id: 'hairgrowth',
        label: 'The Hair Growth Quiz',
        questions: [
            {
                id: 1,
                type: 'quiz',
                questions: [
                    {
                        id: 'q1', // Unique ID for question
                        question: 'Were you recommended by a healthcare professional?',
                        description: 'A growing number of healthcare professionals are recommending KESHAH.',
                        options: ['Yes', 'No'],
                        inputType: 'radio', // Radio input type
                    },
                ],
            },
            {
                id: 2,
                type: 'quiz',
                questions: [
                    {
                        id: 'q2', // Unique ID for question
                        question: 'How much hair have you lost?',
                        description: '', // Consider adding a description for consistency
                        options: [
                            'None (trying to get ahead of it)',
                            'A little (only I notice it)',
                            'Some (those close to me notice)',
                            'A lot (it’s obvious to everyone)',
                        ],
                        inputType: 'radio', // Radio input type
                    },
                ],
            },
            {
                id: 3,
                type: 'info', // Indicates this is an informational item
                p1: 'You’re not alone. Men of varying degrees of hair loss are seeing hair regrowth by targeting scalp tension and inflammation.',
                img1: image01,
                img2: image02,
                img3: image03,
                img4: image04,
                footer: 'Individual results may vary.',
                duration: 60000,
            },
            {
                id: 4,
                type: 'quiz',
                questions: [
                    {
                        id: 'q3', // Unique ID for question 
                        question: 'Where have you noticed hair loss?',
                        options: ['Hairline', 'Crown', 'All over'],
                        inputType: 'radio', // Radio input type
                    },
                ],
            },
            {
                id: 5,
                type: 'info',
                p1: 'Most men lose hair in the hairline and crown because of the structural setup of the skull. Most of the scalp tension is stored in these areas making them the first areas of hair loss.',
                img1: image05,
                p2: 'This groundbreaking study* shows the correlation between high areas of scalp tension (light blue areas) and where most men first experience hair loss (crown and hairline).',
                footer: '*Involvement of Mechanical Stress in Androgenetic Alopecia https://pmc.ncbi.nlm.nih.gov/articles/PMC4639964/',
                duration: 60000,
            },
            {
                id: 6,
                type: 'quiz',
                questions: [
                    {
                        id: 'q4', // Unique ID for question 
                        question: 'When did you start noticing changes to your hair?',
                        options: ['In the past few months', 'In the past year', 'Over a year ago'],
                        inputType: 'radio', // Radio input type
                    },
                ],
            },
            {
                id: 7,
                type: 'quiz',
                questions: [
                    {
                        id: 'q5', // Unique ID for question 
                        question: 'Finding the right solution can be difficult; some people end up feeling like they’ve tried everything. What options have you tried?',
                        options: ['Finasteride', 'Minoxidil', 'PRP', 'Stem cells', 'Hair Transplant', 'Massages', 'Red Light Therapy', 'Shampoos', 'Oils', 'Other', 'I haven’t tried anything'],
                        inputType: 'checkbox', // checkbox input type
                    },
                ],
            },
            {
                id: 8,
                type: 'info',
                p1: 'Most solutions only provide temporary or incomplete results because they fail to target the root cause of the issue which is increased levels of scalp tension and inflammation.',
                p2: 'A recent study* conducted by Japanese Dr. Taro Koyama found that targeting and reducing scalp tension and inflammation through Mechanotherapy provided effective long-term hair regrowth without the use of diets or drugs.',
                footer: '*Increased Hair Thickness by Inducing Stretching Forces https://pubmed.ncbi.nlm.nih.gov/26904154/',
                duration: 60000,
            },
            {
                id: 9,
                type: 'quiz',
                questions: [
                    {
                        id: 'q6', // Unique ID for question 
                        question: 'Do you relate to the following statement?',
                        description: '“My scalp feels inflammed (might include dandruff), and in general unhealthy.”',
                        options: ['Yes', 'Somewhat', 'No'],
                        inputType: 'radio', // Radio input type
                    },
                ],
            },
            {
                id: 10,
                type: 'info',
                p1: 'High levels of inflammation causes an increase in the hormone DHT, which then leads to hair loss. New data shows very specific ingredients (found in India and Japan), can reduce inflammation levels, make your scalp feel healthier, and lead to hair regrowth.',
                duration: 60000,
            },
            {
                id: 11,
                type: 'quiz',
                questions: [
                    {
                        id: 'q7', // Unique ID for question 
                        question: 'With treatment, what results are you hoping for?',
                        description: 'Choose as many as you feel appropriate.',
                        options: ['Keep the hair I have', 'Visibly thicker, fuller hair', 'Regrowth in thinning/bald areas', 'All of the above'],
                        inputType: 'checkbox', // checkbox input type
                    },
                ],
            },
            {
                id: 12,
                type: 'info',
                p1: 'KESHAH is proven to not only stop hair loss & thicken existing hair, but also promote regrowth (even in completely bald areas).',
                img1: image06,
                duration: 60000,
            },
            {
                id: 13,
                type: 'quiz',
                questions: [
                    {
                        id: 'q8', // Unique ID for question 
                        question: 'How does dealing with hair loss impact your life?',
                        description: 'Choose as many as you feel appropriate.',
                        options: ['Nervous to socialize', 'Struggle at work', 'Difficulty dating', 'More/Other'],
                        inputType: 'checkbox', // checkbox input type
                    },
                ],
            },
            {
                id: 14,
                type: 'quiz',
                questions: [
                    {
                        id: 'q9', // Unique ID for question 
                        question: 'Does hair loss run in your family?',
                        options: ['Yes', 'No', 'Not Sure'],
                        inputType: 'radio', // Radio input type
                    },
                ],
            },
            {
                id: 15,
                type: 'info',
                p1: "You inherit your scalp structure from your family, which is why you're more likely to experience scalp tension and hair loss in the same areas as they do.",
                duration: 60000,
            },
            {
                id: 16,
                type: 'quiz',
                questions: [
                    {
                        id: 'q10', // Unique ID for question 
                        question: 'How often do you tend to experience stress?',
                        options: ['All the time', 'Sometimes', 'Rarely', 'Not sure'],
                        inputType: 'radio', // Radio input type
                    },
                ],
            },
            {
                id: 17,
                type: 'info',
                p1: 'Stress causes a ‘flight or fight’ response, causing a tightening of the neck muscles. This causes an increase in scalp tension and leads to inflammation and hair loss.',
                duration: 60000,
            },
            {
                id: 18,
                type: 'quiz',
                questions: [
                    {
                        id: 'q11', // Unique ID for question 
                        question: 'How important is your health to you as you try to regrow your hair?',
                        options: ['Very Important', 'Somewhat important', 'Not that important'],
                        inputType: 'radio', // checkbox input type
                    },
                ],
            },
            {
                id: 19,
                type: 'info',
                p1: 'Unlike drug-based solutions like Finasteride and Minoxidil which can cause health issues like Erectile Dysfunction and Brain Fog, KESHAH is completely natural and has a 0% side effect risk.',
                duration: 60000,
            },
            {
                id: 20,
                type: 'quiz',
                questions: [
                    {
                        id: 'q12', // Unique ID for question 
                        question: 'How much time can you commit to hair regrowth daily.',
                        options: ['15 minutes', '20 minutes', '25 minutes'],
                        description: 'Think of KESHAH like a daily workout, but for your hair. ',
                        inputType: 'radio', // Radio input type
                        recommended: '20 minutes',
                    },
                ],
            },
            {
                id: 21,
                type: 'quiz',
                questions: [
                    {
                        id: 'q13', // Unique ID for question 
                        question: 'Your results are almost ready!',
                        description: 'Let us know where to send them',
                        inputType: 'email',
                        required: true
                    },
                ],
                footer: 'KESHAH will not sell any of your personal information. Ever. That’s a promise.'
            },
            {
                id: 22,
                type: 'info',
                h1: 'Good news!',
                p1: '87% of KESHAH users with your characteristics see their hair loss stop and signs of regrowth in the first 4 months.',
                duration: 60000,
            },
            {
                id: 23,
                type: 'info',
                h1: 'Target the root cause',
                p1: 'KESHAH Mechanotherapy™ targets scalp tension using very specific massaging and stretching techniques, and inflammation using our natural oils composed of Indian and Japanese ingredients.',
                p2: 'Reverse your hair loss from home—no pills, no transplants needed.',
                img1: image07,
                duration: 60000,
            },
            {
                id: 24,
                type: 'info',
                h1: 'Mechanotherapy Sessions',
                p1: 'Open your KESHAH App each day and follow along our step-by-step video Mechanotherapy™ guides created by KESHAH Hair Experts. ',
                img1: image08,
                duration: 60000
            },
            {
                id: 25,
                type: 'info',
                h1: 'The “Super-Oils”',
                p1: "Unlike commonly available oils like Castor, Jojoba, and Rosemary, our two 'super-oils'—made from 12 natural oils and extracts sourced from Japan and India—are proven to reduce inflammation.",
                p2: 'Combined with mechanotherapy sessions, they help regrow your hair.',
                img1: image09,
                duration: 60000,
            },
            {
                id: 26,
                type: 'info',
                h1: 'Oil Ingredients',
                p1: "Each natural ingredient in our treatment, carefully sourced from the Indian Subcontinent and Japan, has been scientifically proven to help combat hair loss.",
                img1: image14,
                img2: image15,
                img3: image16,
                footer: 'Other ingredients: Bhingraj, Neem, Japanese Peppermint, Apricot, Brahmi, Castor. All natural. No chemicals.  ',
                duration: 60000,
            },
            {
                id: 27,
                type: 'info',
                h1: 'We’ll keep you accountable',
                p1: 'Just like working out, consistent effort brings results. The app tracks your completed sessions and sends reminders to help you stay on track.',
                img1: image10,
                duration: 60000,
            },
            {
                id: 28,
                type: 'info',
                h1: 'Live support from a real human',
                p1: 'Your personal companion—a real trained expert, not an AI bot—will be by your side to answer any questions you may have throughout the duration of the treatment.',
                img1: image11,
                duration: 60000,
            },
            {
                id: 29,
                type: 'info',
                h1: 'Track regrowth over time',
                p1: 'The KESHAH app will remind you to take before and after photos every 15 days so we can see hair regrowth over time.',
                img1: image12,
                duration: 60000,
            },
            {
                id: 30,
                type: 'info',
                h1: 'Personalized treatment',
                p1: 'Weekly surveys help us continuously optimize your treatment so you can see the best results based on your hair type.',
                img1: image13,
                duration: 60000,
            },
        ]
    },
    qualify: {
        id: 'qualify',
        label: 'Book A Consultation',
        questions: [
            {
                id: 1,
                type: 'quiz',
                questions: [
                    {
                        id: 'q1', // Unique ID for question
                        question: 'How much hair have you lost?',
                        description: '',
                        options: ['Less than 30%  - mainly I notice it', 'Between 30% - 60% - most people notice it', "More than 60% - I'm nearly bald "],
                        inputType: 'radio', // Radio input type
                    },
                ],
            },
            {
                id: 2,
                type: 'info', // Indicates this is an informational item
                p1: 'You’re not alone. Men of varying degrees of hair loss are seeing hair regrowth by targeting scalp tension and inflammation.',
                img1: image01,
                img2: image02,
                img3: image03,
                img4: image04,
                footer: 'Individual results may vary.',
                duration: 60000,
            },
            {
                id: 3,
                type: 'quiz',
                questions: [
                    {
                        id: 'q2', // Unique ID for question
                        question: 'KESHAH is a natural way to regrow your hair WITHOUT drugs, pills & side-effects. Is this important to you?',
                        description: '',
                        options: ["No, I don't mind taking drugs and pills (please don’t go further)", "Yes, I want a natural and drug-free solution that works"],
                        inputType: 'radio', // Radio input type
                    },
                ],
            },
            {
                id: 4,
                type: 'quiz',
                questions: [
                    {
                        id: 'q3', // Unique ID for question
                        question: 'KESHAH is NOT a quick fix or magic pill. It requires 20 minutes a day for 4 months. Is that something you can commit?',
                        description: '',
                        options: ["No, I can't.", "Yes, I can spend 20 minutes a day for 4 months to regrow my hair."],
                        inputType: 'radio', // Radio input type
                    },
                ],
            },
            {
                id: 5,
                type: 'quiz',
                questions: [
                    {
                        id: 'q4', // Unique ID for question
                        question: 'What results are you hoping for?',
                        description: 'Choose as many as you’d like. ',
                        options: ["Keep the hair I have", "Visibly thicker, fuller hair", "Regrowth in thinning/bald areas", "All of the above"],
                        inputType: 'radio', // Radio input type
                    },
                ],
            },
            {
                id: 6,
                type: 'info',
                p1: 'KESHAH is proven to not only stop hair loss & thicken existing hair, but also promote regrowth (even in completely bald areas).',
                img1: image06,
                duration: 60000,
            },
            {
                id: 7,
                type: 'quiz',
                questions: [
                    {
                        id: 'q5', // Unique ID for question
                        question: "KESHAH's revolutionary treatment is between $800 and $1600. Are you willing to invest this much at this time?",
                        description: '',
                        options: ["No, I am looking for something cheaper.", "Not right now, maybe in the future.", "Yes! I can invest that much for side-effect free hair regrowth."],
                        inputType: 'radio', // Radio input type
                    },
                ],
            },
            {
                id: 8,
                type: 'quiz',
                questions: [
                    {
                        id: 'q6', // Unique ID for question
                        question: "How much can you afford to regrow your hair?",
                        description: 'While KESHAH’s treatment starts at $1600, we believe hair treatment should be accessible to everyone. We enroll 30% of users at a reduced cost. Below are the spots we have left in the reduced-pricing program. ',
                        options: ["I can spend between $100-$200 per month", "I can spend between $200-$300 per month", "I can spend between $300-$400 per month"],
                        inputType: 'radio', // Radio input type
                        optionsAdditionalText: ["0 Spots Left", "5 Spots Left", "8 Spots Left"]
                    },
                ],
            },
        ]
    }
};

export default quizData;
