import React, { useState, useEffect, useCallback } from 'react';
import Screen from './Screen';
import { motion } from 'framer-motion';
import QuizCompleted from './QuizCompleted';
import WalkthroughScreen from './WalkthroughScreen';
import BookConsultation from './BookConsultation';

const Quiz = ({ quizData }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [answers, setAnswers] = useState({});
    const [quizCompleted, setQuizCompleted] = useState(false);
    const [isExiting, setIsExiting] = useState(false);
    const [postQuizStep, setPostQuizStep] = useState(0);

    const handlePrevious = () => {
        if (quizCompleted) {
            if (postQuizStep > 0) {
                setPostQuizStep(prevStep => prevStep - 1);
            } else {
                setIsExiting(true);
                setTimeout(() => {
                    setCurrentIndex(quizData.id === 'qualify' ? quizData?.questions.length - 2 : quizData?.questions.length - 1);
                    setQuizCompleted(false);
                    setIsExiting(false);
                }, 300);
            }
        } else if (currentIndex > 0) {
            setIsExiting(true);
            setTimeout(() => {
                setCurrentIndex(prevIndex => prevIndex - 1);
                setIsExiting(false);
            }, 300);
        }
    };

    const [postQuizScreens, setPostQuizScreens] = useState([]);

    useEffect(() => {
        if (quizData.id === 'hairgrowth') {
            setPostQuizScreens([
                <WalkthroughScreen key="walkthrough" onNext={() => setPostQuizStep(1)} onPrevious={handlePrevious} quizCompleted={quizCompleted} answers={answers} />,
                <QuizCompleted key="completed" answers={answers} onPrevious={handlePrevious} />
            ]);
        } else if (quizData.id === 'qualify') {
            setPostQuizScreens([<BookConsultation key="consultation" onPrevious={handlePrevious} />]);
        }
        // eslint-disable-next-line
    }, [quizData.id, quizCompleted, answers]);

    const handleNext = useCallback(() => {
        if (quizCompleted) {
            // Handle post-quiz steps
            if (postQuizStep < postQuizScreens.length - 1) {
                setPostQuizStep(prevStep => prevStep + 1);
            }
        } else {
            if (currentIndex < quizData?.questions.length - 1) {
                // Check if quizData.id is 'qualify'
                if (quizData.id === 'qualify') {
                    // If the current question is the second to last one (q6), check the answer
                    const currentQuestionId = quizData?.questions[currentIndex]?.questions?.[0]?.id;

                    if (currentQuestionId === 'q5') {
                        const lastSecondQuestionAnswer = answers[currentQuestionId];

                        // If the answer is "No" or "Not right now", move to the next question
                        if (
                            lastSecondQuestionAnswer === "No, I am looking for something cheaper." ||
                            lastSecondQuestionAnswer === "Not right now, maybe in the future."
                        ) {
                            setCurrentIndex(prevIndex => prevIndex + 1);
                        } else {
                            // If not, complete the quiz
                            setQuizCompleted(true);
                        }
                    } else {
                        // For all other questions, just increment the index
                        setIsExiting(true);
                        setTimeout(() => {
                            setCurrentIndex(prevIndex => prevIndex + 1);
                            setIsExiting(false);
                        }, 300);
                    }
                } else {
                    // For non-qualify quizzes, just increment the index
                    setIsExiting(true);
                    setTimeout(() => {
                        setCurrentIndex(prevIndex => prevIndex + 1);
                        setIsExiting(false);
                    }, 300);
                }
            } else {
                // Complete the quiz when reaching the last question
                setIsExiting(true);
                setTimeout(() => {
                    setPostQuizStep(0);
                    setQuizCompleted(true);
                    setIsExiting(false);
                }, 300);
            }
        }
    }, [quizData, currentIndex, quizCompleted, postQuizStep, postQuizScreens.length, answers]);

    const handleInputChange = (value, questionId) => {
        // Ensure the current index is valid
        if (currentIndex >= 0 && currentIndex < quizData?.questions.length) {
            const currentQuiz = quizData?.questions[currentIndex]; // Get the current quiz
            const questionObj = currentQuiz.questions?.find(q => q.id === questionId); // Find the question by ID

            // If question not found, log an error
            if (!questionObj) {
                return console.error("Question not found for the provided questionId:", questionId);
            }

            const { inputType } = questionObj; // Get the input type of the question
            setAnswers(prevAnswers => {
                const newAnswers = { ...prevAnswers }; // Create a copy of previous answers

                // Handling different input types
                if (inputType === 'checkbox') {
                    const currentAnswers = newAnswers[questionId] || []; // Get current answers for the checkbox
                    newAnswers[questionId] = currentAnswers.includes(value)
                        ? currentAnswers.filter(answer => answer !== value) // Remove value if already present
                        : [...currentAnswers, value]; // Add value if not present
                } else if (inputType === 'radio' || inputType === 'text' || inputType === 'email' || inputType === 'textarea') {
                    newAnswers[questionId] = value; // Set the value directly for these input types
                }

                return newAnswers; // Return the updated answers
            });
        }
    };

    useEffect(() => {
        if (quizData?.questions[currentIndex].type === 'info') {
            const timer = setTimeout(() => {
                handleNext();
            }, quizData?.questions[currentIndex].duration || 3000);

            return () => clearTimeout(timer);
        }
    }, [currentIndex, handleNext, quizData?.questions]);

    if (quizCompleted) {
        return (
            <motion.div
                key="post-quiz-screen"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1 }}
            >
                {postQuizScreens[postQuizStep]}
            </motion.div>
        );
    }

    const progress = ((currentIndex + 1) / quizData?.questions.length) * 100;

    return (
        <motion.div
            key={currentIndex}
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: isExiting ? 0 : 1 }}
            transition={{ duration: 0.3 }}
        >
            <Screen
                data={quizData?.questions[currentIndex]}
                onNext={handleNext}
                onPrevious={handlePrevious}
                onInputChange={handleInputChange}
                answers={answers}
                progress={progress}
                currentIndex={currentIndex}
                quizData={quizData}
            />
        </motion.div>
    );
};

export default Quiz;
