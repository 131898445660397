// src/pages/QuizPage.jsx
import React from 'react';
import { useParams } from 'react-router-dom';
import Quiz from '../../components/user/Quiz';
import quizData from '../../data/quizData';

const QuizPage = () => {
    const { quizId } = useParams(); // Extract quizId from route parameters

    // Retrieve the quiz questions based on the quizId from quizData
    const selectedQuiz = quizData[quizId];

    // Handle case where the quizId is invalid or not found
    if (!selectedQuiz) {
        return (
            <div className="flex flex-col items-center justify-center h-screen text-center">
                <h1 className="text-5xl font-bold text-red-500">Quiz Not Found</h1>
                <p className="text-gray-400 max-w-md mt-2">The quiz you are looking for does not exist. Please check the URL or select another quiz.</p>
            </div>
        );
    }

    return <Quiz quizData={selectedQuiz} />;
};

export default QuizPage;
